<template>
  <v-container
    id="subscribe"
    tag="section"
    class="paymentFailed"
  >
    <div>
      <v-row>
        <v-col>
          <v-alert
            type="error"
            class="mint_alert"
            icon="mdi-thumb-down"
            prominent
          >
            <v-row align="center">
              <v-col class="grow">
                <h3>There was a problem in taking you payment, please don't worry, you haven't been charged. Please try again.</h3>
              </v-col>
              <v-col class="shrink">
                <v-btn
                  x-large
                  color="success"
                  class="steelyGreyBtn"
                  width="100%"
                  to="/">Try again</v-btn>
              </v-col>
            </v-row>
          </v-alert>
        </v-col>
      </v-row>

    </div>
  </v-container>
</template>

<script>
  export default {
    name: 'HomeSubscribe',
    data() {
      return {
        insurance_type: sessionStorage.getItem('insurance_type'),
        AmountDue: JSON.parse(sessionStorage.getItem('AmountDue')),
        RenewalDate: sessionStorage.getItem('RenewalDate')
      };
    },
    methods: {
      getCurrentDate: function () {
        var currentDateWithFormat = new Date().toJSON().slice(0,10).replace(/-/g,'/');
      }
    }
  }
</script>
